import React, { Fragment } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import BlockTwoColumnCarousel from 'components/blocks/BlockTwoColumnCarousel/BlockTwoColumnCarousel';
import Link from 'components/ui/Link';

import styles from './Hero.module.scss';

const Hero = ({
    creators,
    title,
    images,
    slug,
    attributes,
    recipesConfig,
    className,
    theme,
}) => {
    theme ??= 'default';

    const attributesSorted = attributes?.meal?.sort((a, b) => a - b);
    const mealAttributes = recipesConfig?.meal.options;

    const mealTypes = attributesSorted
        ?.map(attr => {
            const match = mealAttributes?.filter(filter => {
                return filter.id === parseInt(attr, 10);
            });

            return match[0]?.label || false;
        })
        .filter(item => item !== false);

    const cta = {
        link: {
            slug: `recipes/${slug}`,
        },
        text: 'View Recipe',
        style: 'filled-cream-ocean',
    };

    const renderCreators = () => {
        const length = creators?.length;

        if (length === 0) return;

        return (
            <div className={styles.creators}>
                By:{' '}
                {creators?.map((creator, index) => {
                    const lastItem = length === index + 1;
                    const secondLastItem = length === index + 2;

                    const separator = lastItem
                        ? ''
                        : secondLastItem
                        ? ' & '
                        : ', ';

                    return (
                        <Fragment key={index}>
                            {creator.link ? (
                                <Link href={creator.link}>{creator?.name}</Link>
                            ) : (
                                creator?.name
                            )}
                            {separator}
                        </Fragment>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={cx(styles.root, className)}>
            <BlockTwoColumnCarousel
                eyebrow={mealTypes && mealTypes[0]}
                headline={title}
                headlineTag="h1"
                images={images}
                firstBlock={true}
                cta={theme === 'recipes' ? cta : {}}
                theme={theme}
            >
                {renderCreators()}
            </BlockTwoColumnCarousel>
        </div>
    );
};

Hero.propTypes = {
    attributes: PropTypes.shape({
        meal: PropTypes.array,
    }),
    className: PropTypes.string,
    creators: PropTypes.array,
    images: PropTypes.array,
    recipesConfig: PropTypes.object,
    slug: PropTypes.string,
    theme: PropTypes.string,
    title: PropTypes.string,
};

export default Hero;
